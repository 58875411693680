// Bootstrap
@import '../../node_modules/bootstrap/scss/bootstrap.scss';

// Swiper
@import '../../node_modules/swiper/swiper.scss';

// Fontawesome
@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/brands.scss';

// Fonts
@import 'fonts.scss';

// Variables
$color_font: rgb(0, 0, 0);
$maha_red: #dc3e3d;
$bg_silver: rgb(225, 240, 255);
$border: rgb(128, 150, 168);
$color_footer_bottom: rgb(128, 150, 168);
$color_footer_nav: rgb(000, 044, 067);
$color_selected: rgb(180, 195, 205);
.text-maha-red {
    color: $maha_red;
}

.bg-danger {
    background-color: $maha_red !important;
}

// Pagination
.pagination {
    --bs-pagination-active-bg: rgb(128, 150, 168);
    --bs-pagination-border-color: rgb(128, 150, 168);
    --bs-pagination-color: rgb(000, 044, 067);
    --bs-pagination-active-border-color: rgb(128, 150, 168);
    --bs-pagination-hover-border-color: rgb(128, 150, 168);
    --bs-pagination-hover-color: rgb(000, 044, 067);
    --bs-pagination-disabled-color: darkgray;
    --bs-pagination-disabled-border-color: rgb(128, 150, 168);
    --bs-pagination-font-size: 0.8rem;
    --bs-pagination-padding-x: 0.5rem;
    --bs-pagination-padding-y: 0.3rem;
    @media (min-width: 768px) {
        --bs-pagination-font-size: 1rem;
        --bs-pagination-padding-x: 0.75rem;
        --bs-pagination-padding-y: 0.375rem;
    }
}

// Button
.btn-secondary {
    --bs-btn-bg: #b4c3cd;
    --bs-btn-border-color: #8096a8;
    --bs-btn-color: rgb(0, 0, 0);
}

// Artical Colors
.article-color-01,
.article-color-02 {
    //'weiss' => '1', 'weiss/kombi' => '2',
    background-color: rgb(255, 255, 255) !important;
}

.article-color-03,
.article-color-04 {
    // 'schwarz' => '3', 'schwarz/kombi' => '4',
    background-color: rgb(0, 0, 0) !important;
}

.article-color-05 {
    // 'rose' => '5',
    background-color: #ff007f !important;
}

.article-color-06 {
    // 'natur' => '6',
    background-color: #f5f5dc !important;
}

.article-color-07 {
    // 'beige' => '7',
    background-color: #f5f5dc !important;
}

.article-color-08 {
    // 'eierschale' => '8',
    background-color: #fffdd0 !important;
}

.article-color-09 {
    // 'blau' => '9',
    background-color: #0d6efd !important;
}

.article-color-10 {
    // 'hellbraun' => '10',
    background-color: #b47d49 !important;
}

.article-color-11 {
    // 'dunkelbraun' => '11',
    background-color: #5b3a29 !important;
}

.article-color-12 {
    // 'grau' => '12',
    background-color: #6c757d !important;
}

.article-color-13 {
    // 'rot' => '13',
    background-color: #dc3545 !important;
}

.article-color-14 {
    // 'burgund' => '14',
    background-color: #5e2028 !important;
}

.article-color-15 {
    // 'lila' => '15',
    background-color: #800080 !important;
}

.article-color-16 {
    // 'pink' => '16',
    background-color: #ffc0cb !important;
}

.article-color-17 {
    // 'grün' => '17',
    background-color: #198754 !important;
}

.article-color-18 {
    // 'gelb' => '18',
    background-color: #ffc107 !important;
}

.article-color-19 {
    // 'tuerkis' => '19',
    background-color: #3f888f !important;
}

.article-color-20 {
    // 'oliv' => '20',
    background-color: #4b573e !important;
}

.article-color-21 {
    // 'gold' => '21',
    background-color: #ffd700 !important;
}

.article-color-22 {
    // 'silber' => '22',
    background-color: #c0c0c0 !important;
}

.article-color-23 {
    // 'bunt' => '23',
    background-color: rgba(255, 193, 7, 1) !important;
}

.article-color-24 {
    // 'khaki' => '24',
    background-color: #c0ad8c !important;
}

.article-color-25 {
    // 'rosa' => '25',
    background-color: #ffc0cb !important;
}

.article-color-26 {
    // 'schlamm' => '26',
    background-color: #d7c7bb !important;
}

.article-color-27 {
    // 'fuchsia' => '27',
    background-color: #ff00ff !important;
}

.article-color-28 {
    // 'hellblau' => '28',
    background-color: #7fffd4 !important;
}

.article-color-29 {
    // 'orange' => '29',
    background-color: #fd7e14 !important;
}

.article-color-30 {
    // 'platin' => '30',
    background-color: #7f7679 !important;
}

.article-color-31 {
    // 'bronce' => '31',
    background-color: #bf8970 !important;
}

.article-color-32 {
    // 'rost' => '32',
    background-color: #c04611 !important;
}

.article-color-33 {
    // 'türkis' => '33',
    background-color: #3f888f !important;
}

.article-color-34 {
    // 'offwhite' => '34',
    background-color: #faf9f6 !important;
}

.article-color-35 {
    // 'ocker' => '35',
    background-color: #b88428 !important;
}

.article-color-36 {
    // 'petrol' => '36',
    background-color: #216477 !important;
}

.article-color-37 {
    // 'sortiert' => '37',
    background-color: #fff !important;
}

.article-color-38 {
    // 'kupfer' => '38',
    background-color: #8e402a !important;
}

.article-color-39 {
    // 'altrosa' => '39',
    background-color: #d47479 !important;
}

.article-color-40 {
    // 'verschiedene Farben' => '40'
    background-color: #fff !important;
}

// General
*,
body {
    font-family: 'Ubuntu';
    color: $color_font;
}

@media (min-width: 1400px) {
    .container-xxl,
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1224px;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-weight: 500;
}

h1,
.h1 {
    font-size: 22px;
}

h2,
.h2 {
    font-size: 20px;
}

h3,
.h3 {
    font-size: 18px;
}

h4,
.h4 {
    font-size: 16px;
}

.form-control,
.input-group-text {
    border-color: $border;
}

.input-group-text {
    background-color: $color_selected;
}

.content a {
    color: $color_font;
    text-decoration: underline;

    &:hover,
    &:active {
        color: $color_footer_bottom;
    }
}

.overlay-hover,
.maps-link {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: background-color 0.3s ease-in-out;

    &:hover {
        background-color: rgba(128, 150, 168, 0.5);
        transition: background-color 0.3s ease-in-out;
    }
}

// Topbar
.topbar {
    background-color: $bg_silver;
    padding: 0;
    @media (min-width: 576px) {
        padding-right: calc(var(--bs-gutter-x) * 0.5);
        padding-left: calc(var(--bs-gutter-x) * 0.5);
        border-bottom: 1px solid $border;
    }

    .navbar {
        padding: 0;

        .container {
            justify-content: flex-start;
            padding: 0;
            @media (min-width: 576px) {
                justify-content: flex-end;
                padding-right: calc(var(--bs-gutter-x) * 0.5);
                padding-left: calc(var(--bs-gutter-x) * 0.5);
            }

            .topbar-icons {
                display: flex;
                min-height: 56px;
                width: 100%;
                align-items: center;
                border-bottom: 1px solid $border;
                padding-right: calc(var(--bs-gutter-x) * 0.5);
                padding-left: calc(var(--bs-gutter-x) * 0.5);
                @media (min-width: 576px) {
                    border: none;
                    justify-content: flex-end;
                    padding: 0;
                }

                .topbar-icon {
                    display: flex;
                    margin-left: 27px;
                    text-decoration: none;

                    .top-0 {
                        top: 5px !important;
                    }

                    .fa-regular {
                        font-size: 22px;
                        position: relative;
                    }

                    .topbar-icon-text {
                        display: none;
                        font-size: 10px;
                        margin-left: 8px;
                        @media (min-width: 576px) {
                            display: inline-block;
                        }
                    }
                }

                .navbar-toggler {
                    margin-left: auto;
                    border: 0;
                    @media (min-width: 576px) {
                        display: none;
                    }
                }
            }

            .navbar-collapse {
                background-color: #fff;

                li {
                    border-bottom: 1px solid $border;

                    &:hover .nav-link {
                        background-color: rgb(233, 236, 239);
                    }

                    a {
                        padding-right: calc(var(--bs-gutter-x) * 0.5);
                        padding-left: calc(var(--bs-gutter-x) * 0.5);
                        color: $color_font;
                    }

                    .dropdown-toggle.show {
                        border-bottom: 1px solid $border;
                    }

                    .dropdown-menu {
                        border: none;
                        padding: 0;

                        :last-child {
                            border: none;
                        }
                    }

                    a.dropdown-item {
                        padding: var(--bs-dropdown-item-padding-y) 2rem;
                    }
                }

                .btn-close-mobile-navbar {
                    background-color: $color_footer_bottom;
                    position: relative;

                    &:hover {
                        a {
                            color: #fff;
                            background-color: $color_footer_bottom;
                        }

                        .fa-xmark {
                            color: #fff;
                        }
                    }

                    .fa-xmark {
                        position: absolute;
                        color: $color_footer_bottom;
                        right: 0;
                        top: 0;
                        padding: 4px 12px;
                        font-size: 32px;
                        background-color: $color_footer_nav;
                    }
                }
            }
        }
    }
}

// Header
.head {
    .row {
        min-height: 75px;
        align-items: center;

        .navbar-brand {
            display: flex;
            margin: 15px 0;
            @media (min-width: 992px) {
                margin: 0;
            }

            img {
                width: 20%;
                height: auto;
                @media (min-width: 600px) {
                    width: 140px;
                }
            }

            span {
                @media (max-width: 350px) {
                    font-size: 14px;
                }
                @media (max-width: 500px) {
                    font-size: 18px;
                }
                padding-left: 20px;
                font-size: 20px;
                margin-top: auto;
                padding-bottom: 5px;
            }
        }

        form {
            display: contents;
        }

        .search {
            margin-bottom: 15px;
            @media (min-width: 992px) {
                margin: 0;
            }
        }
    }
}

// Slider
.slider {
    margin-bottom: 20px;

    .carousel-caption {
        padding: 0;
    }

    .carousel-caption {
        right: 0;
        left: 0;
        bottom: 0;
    }

    .carousel-caption-inner {
        display: none;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.3);
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        @media (min-width: 991px) {
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
        }

        h5 {
            font-weight: 300;
            font-size: 1rem;
            color: #000;
            margin-bottom: 0.2rem;
            @media (min-width: 768px) {
                font-size: 1.5rem;
            }
        }

        p {
            font-size: 0.75rem;
            margin-bottom: 0;
            @media (min-width: 768px) {
                font-size: 1.5rem;
            }
        }
    }
}

// Product Menu
.product-menu {
    display: none;
    position: relative;
    margin-bottom: 20px;
    background-color: $bg_silver;
    @media (min-width: 576px) {
        display: block;
    }

    .navbar {
        padding-top: 4px;
        padding-bottom: 4px;

        .nav-link {
            color: $color_font;
            padding-left: 0;
            padding-right: 45px;
        }

        .dropdown-menu {
            background-color: #fff;

            .dropdown-item {
                font-size: 0.8rem;
                padding: 2px 8px;
            }

            a:active {
                background-color: var(--bs-dropdown-link-hover-bg);
                color: $color_font;
            }
        }
    }
}

// Produkte
.products-header {
    margin-bottom: 20px;
    display: inline-flex;
    align-items: baseline;

    .filter-wrapper {
        cursor: pointer;
        padding: 0 10px;

        .fa-solid {
            font-size: 20px;
        }
    }

    .category-name {
        font-size: 22px;
        text-transform: capitalize;
    }

    .products-count,
    .category-name {
        margin-bottom: 0;
    }
}

.wrapper-products-listing {
    margin-bottom: 20px;
    background-color: $bg_silver;
    border: 1px solid $border;
    padding-left: 10px;
    padding-right: 10px;

    .products-list {
        padding: 5px;

        & > [class^='col-'] {
            padding: 5px;
        }

        @media (min-width: 768px) {
            padding: 10px;

            & > [class^='col-'] {
                padding: 10px;
            }
        }
    }

    .products-list .product-item {
        padding: 0;
        position: relative;
        border: 1px solid $border;
        background-color: #fff;
        display: flex;
        justify-content: center;

        .product-title {
            text-transform: capitalize;
            position: absolute;
            width: 100%;
            text-align: center;
            font-size: 14px;
            margin: 5px 0;
            cursor: pointer;
            left: 0;
            top: 0;
            @media (min-width: 450px) {
                font-size: 16px;
            }
            @media (min-width: 576px) {
                font-size: 18px;
            }
        }

        .sale-icon {
            font-size: 0.8rem;
            font-weight: 500;
            background-color: #e10000;
            color: #fff;
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 2px 4px;
            @media (min-width: 991px) {
                font-size: 0.9rem;
                padding: 8px;
            }
        }
    }

    img {
        max-width: 100%;
        max-height: 100%;
        align-self: center;
        cursor: pointer;
        padding: 30px 5px 5px 5px;
        @media (min-width: 450px) {
            padding: 30px 15px 15px 15px;
        }
        @media (min-width: 576px) {
            padding: 30px;
        }

    }

    .alert {
        margin: 0;
    }
}

.modal-dialog {
    .modal-content {
        padding: 5px;

        .row > div {
            padding: 5px;
        }
    }

    @media (min-width: 768px) {
        max-width: 740px;
        .modal-content {
            height: 740px;
        }
    }

    .modal-product-header {
        .carousel-indicators {
            button {
                background-color: $border;
            }
        }
    }

    .modal-product-description {
        padding: 15px;
        overflow-x: scroll;

        table tr{
            vertical-align: middle;
        }

        .modal-title {
            text-transform: capitalize;
            font-size: 32px;
            font-weight: 500;
            margin-bottom: 20px;
            line-height: normal;
        }

        .property {
            text-transform: capitalize;

            &.property-price {
                text-transform: none;
            }

            .list-group-item {
                font-size: 14px;
                padding: 2px 5px;
                border: 1px solid $border;
                border-radius: 0;
                margin-right: 3px;

                &.disable {
                    color: var(--bs-gray-400);
                    border-color: var(--bs-gray-400);
                }
            }

            .color .list-group-item {
                content: ' ';
                width: 28px;
                height: 27px;
            }

            .badge {
                font-weight: 300;
                background-color: $border;
                color: white;
            }
        }

        .label {
            font-size: 18px;
            width: 20%;
        }

        .price {
            font-size: 20px;
            font-weight: 500;
        }

        .price.sale{
            color: #e10000;
            s{
                color: rgba(0,0,0,0.5);
            }
            sup{
                color: #e10000;
            }
        }

        .fa-solid,
        .fa-regular,
        .fa-heart {
            font-size: 24px;
            margin-left: 5px;
            cursor: pointer;
        }

        .fa-solid {
            color: $maha_red;
        }
    }

    .modul-product-slider {
        img {
            width: 100%;
            padding: 5px;
            border: 1px solid $border;
            margin-bottom: -1px;
        }
    }

    .carousel-item {
        border: 1px solid $border;

        img {
            max-height: 341px;
            margin: 0 auto;
            padding: 15px;
        }

    }

    .carousel-inner{
        .sale-icon {
            font-size: 0.8rem;
            font-weight: 500;
            background-color: #e10000;
            color: #fff;
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 2px 4px;
            @media (min-width: 991px) {
                font-size: 0.9rem;
                padding: 8px;
            }
        }
    }

    .btn-close {
        position: absolute;
        top: 0;
        right: 0;
        width: 3rem;
        height: 3rem;
        z-index: 255;
    }
}

// Content
.content-wrapper {
    margin-bottom: 50px;

    .site-header {
        margin-bottom: 20px;
        display: inline-flex;
        align-items: baseline;

        .title {
            font-size: 22px;
            margin-bottom: 0;
        }
    }
}

// Hersteller
.wrapper-manufacturers {
    background-color: $bg_silver;
    margin-bottom: 20px;
    height: 50px;

    .swiper-wrapper {
        transition-timing-function: linear;
    }

    .swiper-slide {
        text-transform: uppercase;
        background-color: transparent;
        border: 0;
        padding: 12px 0;
        font-size: 18px;
        text-align: center;
        width: auto;
        margin: 0 25px;
    }
}

// Modul Boxen
.wrapper-modules {
    margin-bottom: 20px;
    background-color: $bg_silver;
    border: 1px solid $border;
    padding-left: 10px;
    padding-right: 10px;

    .row {
        padding: 10px;
    }

    .row > div {
        padding: 10px;
    }

    .module {
        position: relative;
        width: 100%;
        background-color: #fff;
        border: 1px solid $border;

        &:before {
            content: '';
            display: block;
            padding-top: 80%;
            @media (min-width: 768px) {
                padding-top: 140%;
            }
            @media (min-width: 991px) {
                padding-top: 100%;
            }
        }

        .content {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: hidden;

            .module-title {
                margin: 5px 0;
            }

            .module-title,
            p {
                text-align: center;
                margin-bottom: 30px;
                font-size: 20px;
                @media (min-width: 768px) {
                    font-size: 16px;
                    margin-bottom: 5px;
                }
                @media (min-width: 991px) {
                    font-size: 16px;
                    margin-bottom: 15px;
                }
                @media (min-width: 1200px) {
                    font-size: 18px;
                    margin-bottom: 25px;
                }
            }

            .module-title-maps {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                background-color: #fff;
                margin: 0;
                padding: 5px 0;
                text-align: center;
            }

            .module-map {
                width: 100%;
                height: auto;
                @media (min-width: 768px) {
                    width: auto;
                    height: 100%;
                }
            }
        }
    }
}

// Footer
footer {
    .footer_nav {
        background-color: $color_footer_nav;
        padding-top: 20px;
        padding-bottom: 20px;
        //min-height: 340px;
        .row {
            margin: 0;
            padding: 0 19px;
        }

        .border-left {
            border-left: 1px solid rgb(255, 255, 255);
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            @media (min-width: 768px) {
                margin-bottom: 0;
            }
        }

        .header_footer_nav {
            color: rgb(255, 255, 255);
            font-size: 22px;
            padding-left: 28px;
            margin-bottom: 30px;
        }

        .list-group {
            margin-bottom: 50px;
            @media (min-width: 768px) {
                margin-bottom: 75px;
            }

            .list-group-item {
                background-color: transparent;
                border: none;
                padding: 0 0 0 28px;

                small {
                    color: rgb(255, 255, 255);
                }

                a {
                    text-decoration: none;
                    color: rgb(255, 255, 255);
                    font-size: 16px;

                    &:hover {
                        color: $color_selected;
                    }
                }
            }
        }
    }

    .footer_copyright {
        background-color: $color_footer_bottom;

        p {
            margin: 9px;
        }
    }
}

// DEBUG Browser Width
#browser-width {
    display: none;
    background-color: red;
    position: fixed;
    color: white;
    padding: 5px;
    top: 0;
    left: 0;
}
